import * as React from "react";
import Navbar from "../components/navbar";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import GoBackButton from "../components/goBackButton";

const Product = () => {
  const [mobile, setMobile] = React.useState(false);
  React.useEffect(() => {
    const { innerWidth: width } = window;
    width > 750 ? setMobile(false) : setMobile(true);
  }, []);

  const elementRef = React.useRef();
  const [elHeight, setElHeight] = React.useState(315);
  React.useEffect(() => {
    setElHeight(elementRef.current?.clientHeight);
  }, [elementRef]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <title>Merth.dev mobile-app</title>
      </Helmet>
      <Navbar />
      <main className=" bg-primary">
        <h1 className="text-center">Fashion Mobile App</h1>
        <div className="row justify-content-center">
          <div className="row py-4 h-100 ">
            <div
              className={`col-md-6 text-center  h-100 align-self-center ${
                mobile ? "g-0 pb-1" : "px-4"
              }`}
            >
              <iframe
                width="100%"
                height={`${!mobile ? elHeight + "px" : ""}`}
                src="https://www.youtube.com/embed/gQUYxIOZWqw"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div ref={elementRef} className="col-md-6 bg-paragraph">
              <p
                className={` ${mobile ? "p-2 fs-6" : "p-4 justify-text fs-5"}`}
              >
                This fashion mobile application features a minimalist design
                with auto-detection of the user's phone language and a
                bright/dark interface color mode. Its primary goal is to provide
                customers with quick and easy access to the latest models.
                Photographers upload new photos daily to a hosting server via
                FTP, while a Node.js app monitors the uploading folder in the
                background. Any changes (additions, deletions, or renames)
                trigger an indexing function that updates the database with
                recent URLs, ensuring immediate accessibility for newly uploaded
                models. The app is available on the Google Play Store and will
                soon be deployed on the Apple App Store.
                <br />
                <i>
                  <small>
                    Used technologies : React Native ,NodeJs and
                    SQLite/Sequelize.
                  </small>
                </i>
              </p>
            </div>
          </div>
        </div>
        <div className={`row ${mobile ? "" : "px-3"}`}>
          <StaticImage
            src={`./../images/mobileApp.jpg`}
            alt="service"
            placeholder="blurred"
          />
        </div>
        <GoBackButton />
      </main>
    </>
  );
};

export default Product;
